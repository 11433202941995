import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Element, Link } from 'react-scroll'
import { AiFillCaretDown, AiOutlineClose } from 'react-icons/ai'

export default function MYNavbar() {

    const [toggled, setToggled] = useState(false)

    const onToggle = () => {
        setToggled(!toggled)

    }

    return (
        <div>
            <Navbar
                expand="lg"
                fixed="top"
                className='navbar-main'
                onToggle={onToggle}

            >
                <Container>
                    <Navbar.Brand href="#home" className='brand-name'>Alivany Medical & <br /> Dental Supplies</Navbar.Brand>
                    <Navbar.Toggle className='mobile-toggle-nav-btn' aria-controls="basic-navbar-nav">{toggled ? <AiOutlineClose size={30} /> : null}</Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className='nav-collapse'>
                        <Nav className="ms-auto collapsed-items" >
                            <Link to="about" spy={true} smooth={true} offset={-100} duration={500} className="nav-links">About Us</Link>
                            <Link to="products" spy={true} smooth={true} offset={-120} duration={500} className="nav-links">Products</Link>
                            <Link to="contact" spy={true} smooth={true} offset={-100} duration={500} className="nav-links">Contact Us</Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
