import React from 'react'
import Message from "../assets/get.png"


export default function Footer() {
    return (

        <div>
            <div className='footer-div' id="contact">
                <div>
                    <h1 class="heading">Get in Touch</h1>
                    <div class="social-media">
                        <a
                            href="mailto:info@alivanymedicalsupplies.com"
                            class="footer-links"

                        >
                            info@alivanymedicalsupplies.com
                        </a>
                        <br />
                        <br />
                        <br />
                        <a href="tel:+233303955917" target="_blank" class="footer-links">
                            +233 030 395 5917 </a>
                        <br />
                    </div>
                </div>

                <div>
                    <img className='img-fluid footer-img' src={Message} />
                </div>
            </div>



        </div>
    )
}
