import React from 'react'
import { Card, CardGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import hypo from '../../assets/hypo.webp'
import hibi from '../../assets/hibi.jpeg'
import spirit from '../../assets/spirit.jpeg'
import detol from '../../assets/detol.webp'
import gi from '../../assets/gi.jpeg'
import hand from '../../assets/hand.webp'




export default function Card7() {

    const handleGetinTouch = () => {
        Swal.fire({
            html: '<button><a href="tel:+233303955917">Call: 0303955917</a></button><button><a href="mailto:info@alivanymedicalsupplies.com">Email:info@alivanymedicalsupplies.com</a></button>',
            confirmButtonColor: '#ccc',
            confirmButtonText: 'X',
            title: 'Lets Talk',
            customClass: {
                title: 'my-title-class',
                htmlContainer: 'my-html-container-class',
                confirmButton: 'my-confirm-button-class'
            }
        });
    }

    const data = [
        {
            id: 1,
            name: 'Hypochlorite',
            imgSrc: hypo,

        },
        {
            id: 2,
            name: 'Hibiscrub',
            imgSrc: hibi,

        },

        {
            id: 3,
            name: 'methylated spirit',
            imgSrc: spirit,

        },

        {
            id: 4,
            name: 'Dettol Antiseptic',
            imgSrc: detol,

        },

        // {
        //     id: 5,
        //     name: 'Dental composite material',
        //     imgSrc: gi,

        // },

        // {
        //     id: 6,
        //     name: 'Dental composite material',
        //     imgSrc: hand,

        // },





    ]



    return (
        <div>
            <div>
                <div>
                    <div className='cards-container'>
                        {
                            data.map((item) => {
                                return (
                                    <div key={item.id} >
                                        <Card className='each-card'>
                                            <Card.Img className='card-img img-fluid' variant="top" src={item.imgSrc} />
                                            <Card.Body className='card-body'>
                                                <Card.Title className='card-title'>{item.name}</Card.Title>
                                                <a onClick={handleGetinTouch} style={{ alignSelf: 'center' }}>
                                                    <Button variant="primary" className='products-get-in-touch'>Get in Touch</Button>
                                                </a>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
