import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

export default function MYCarousel() {
    return (
        <div>
            <Carousel className='carousel'>
                <Carousel.Item>
                    <img
                        className="d-block w-100 carou-img"
                        src="https://images.pexels.com/photos/3938022/pexels-photo-3938022.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="First slide"
                    />
                    <Carousel.Caption className='carousel-caption'>
                        <h1>Leading the way in innovative medical equipment solutions</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 carou-img"
                        src="https://images.pexels.com/photos/4562895/pexels-photo-4562895.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="Second slide"
                    />

                    <Carousel.Caption className='carousel-caption'>
                        <h1>Discover cutting-edge medical technology for better patient outcomes.</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 carou-img"
                        src="https://images.pexels.com/photos/287237/pexels-photo-287237.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="Third slide"
                    />

                    <Carousel.Caption className='carousel-caption'>
                        <h1>Elevating medical care with state-of-the-art equipment solutions</h1>
                    </Carousel.Caption>
                </Carousel.Item>

                {/* <Carousel.Item>
                    <img
                        className="d-block w-100 carou-img"
                        src="https://images.pexels.com/photos/4989168/pexels-photo-4989168.jpeg?auto=compress&cs=tinysrgb&w=1600"
                        alt="Third slide"
                    />

                    <Carousel.Caption className='carousel-caption'>
                        <h1>Advanced medical equipment for the most complex medical challenges.</h1>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
        </div>
    )
}
