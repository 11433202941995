import React from 'react'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import vid from "../assets/vid.mp4"

export default function About() {
    return (



        // </div>
        <div className='about-us-div' id='about'>
            <h1 className='heading'>About Us</h1>
            <div className='about-row'>
                <div className='about-image-div' >
                    <video

                        aria-label=""
                        muted loop autoPlay playsInline className='video'>
                        <source src={vid} className='video' type="video/mp4" />
                    </video>

                </div>
                <div sm={6} className='about-div-text'>

                    Alivany Medical & Dental Supplies Company Limited is a leader
                    in providing medical supplies to healthcare facilities, organizations,
                    and individuals across Ghana and beyond. <br />

                    Founded in 2015, Alivany Medical & Dental Supplies has grown
                    to become a trusted partner for medical supplies in Africa,
                    offering a wide range of high-quality products such as
                    surgical instruments, hospital equipment, medical consumables,
                    medical engineering services, and laboratory supplies.
                    With a dedicated team of experienced professionals and a
                    commitment to providing excellent customer service,
                    Alivany Medical & Dental Supplies has earned a reputation
                    for reliability, efficiency, and affordability.


                    <br />
                    <br />

                    Alivany Medical and Dental Supplies Company Limited is
                    your one-stop-shop for all your medical device needs.
                    We specialize in the calibration, installation, servicing,
                    repairs, and maintenance of a wide range of medical devices
                    to ensure that they are running optimally and accurately at all times.
                    <br />
                    <br />

                    Alivany Medical and Dental Supplies is a major distributor
                    for various international brands in Europe, USA, North America,
                    and Asia. Our clients include various Teaching Hospitals,
                    Private Hospitals, Municipal Hospitals, Health Centers,
                    Private Clinics & Maternity Homes.
                    <br />
                    <br />




                </div>
            </div>
        </div>

    )
}
