import React from 'react'
import { Card, CardGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import reagents from '../../assets/reagents.webp'
import latext from '../../assets/latext.webp'
import nose from '../../assets/nose.jpg'
import gnae from '../../assets/gynae.jpeg'
import surgical from '../../assets/surg.jpeg'
import cat from '../../assets/cat.webp'
import ng from '../../assets/ng.jpeg'
import pack from '../../assets/pack.jpeg'
import syringes from '../../assets/syringes.webp'


export default function Card4() {

    const handleGetinTouch = () => {
        Swal.fire({
            html: '<button><a href="tel:+233303955917">Call: 0303955917</a></button><button><a href="mailto:info@alivanymedicalsupplies.com">Email:info@alivanymedicalsupplies.com</a></button>',
            confirmButtonColor: '#ccc',
            confirmButtonText: 'X',
            title: 'Lets Talk',
            customClass: {
                title: 'my-title-class',
                htmlContainer: 'my-html-container-class',
                confirmButton: 'my-confirm-button-class'
            }
        })
    }

    const data = [
        {
            id: 1,
            name: 'Latex Examination Gloves',
            imgSrc: latext,

        },
        {
            id: 2,
            name: 'Gynaecological Gloves',
            imgSrc: gnae,

        },

        {
            id: 3,
            name: 'Surgical Gloves',
            imgSrc: surgical,

        },

        {
            id: 4,
            name: 'Syringes',
            imgSrc: syringes,

        },

        {
            id: 5,
            name: 'Urinary Catheter & NG tubes',
            imgSrc: cat,

        },

        {
            id: 6,
            name: 'laparotomy & Caesarean Pack',
            imgSrc: pack,

        },



    ]

    return (
        <div>
            <div>
                <div>
                    <div className='cards-container'>
                        {
                            data.map((item) => {
                                return (
                                    <div key={item.id} >
                                        <Card className='each-card' >
                                            <Card.Img className='card-img img-fluid' variant="top" src={item.imgSrc} />
                                            <Card.Body className='card-body'>
                                                <Card.Title className='card-title'>{item.name}</Card.Title>
                                                <a onClick={handleGetinTouch} style={{ alignSelf: 'center' }}>
                                                    <Button variant="primary" className='products-get-in-touch'>Get in Touch</Button>
                                                </a>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
