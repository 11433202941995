import logo from './logo.svg';
import MainPage from './MainPage';

function App() {
  return (
    <>
      <MainPage />
    </>
  );
}

export default App;
