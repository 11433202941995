import React from 'react'
import { Card, CardGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import biomed from '../../assets/biomed.jpeg'
import Swal from 'sweetalert2';




export default function Card6() {

    const handleGetinTouch = () => {
        Swal.fire({
            html: '<button><a href="tel:+233303955917">Call: 0303955917</a></button><button><a href="mailto:info@alivanymedicalsupplies.com">Email:info@alivanymedicalsupplies.com</a></button>',
            confirmButtonColor: '#ccc',
            confirmButtonText: 'X',
            title: 'Lets Talk',
            customClass: {
                title: 'my-title-class',
                htmlContainer: 'my-html-container-class',
                confirmButton: 'my-confirm-button-class'
            }
        })
    }


    const data = [
        {
            id: 1,
            name: `Our team of experienced biomedical engineers uses 
            cutting-edge technology and scientific principles to develop 
            innovative solutions that meet the specific needs of our clients.
            We provide a range of services, including feasibility studies,
             product development, testing, regulatory compliance, and manufacturing. 
             Our services cover a range of areas, including medical imaging, 
             clinical engineering, and medical device design. 
             We also offer consulting services to help our clients 
             navigate complex regulatory environments and ensure that
              their products meet industry standards.
            
            We are committed to providing our clients with the highest
            quality services, delivered in a timely and cost-effective manner. 
            We pride ourselves on our ability to work closely with our clients 
            to understand their unique needs and to develop customized solutions
             that meet those needs. With our expertise in biomedical engineering 
             and our commitment to quality, we are confident that we can help our 
             clients achieve their goals and improve the quality of healthcare delivery.`,
            imgSrc: biomed,

        },


    ]

    return (
        <div>
            <div>
                <div>
                    <div className='cards-container'>
                        {
                            data.map((item) => {
                                return (
                                    <div key={item.id} >
                                        <Card className='' >
                                            <Card.Img className='img-fluid' variant="top" src={item.imgSrc} />
                                            <Card.Body className='card-body'>

                                                <Card.Text className='card-text'>{item.name}</Card.Text>
                                                <a onClick={handleGetinTouch} style={{ alignSelf: 'center' }}>
                                                    <Button variant="primary" className='products-get-in-touch'>Get in Touch</Button>
                                                </a>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
