import React, { useState } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Card1 from './ProductTabs/Card1';
import Card2 from './ProductTabs/Card2';
import Card3 from './ProductTabs/Card3';
import Card4 from './ProductTabs/Card4';
import Card5 from './ProductTabs/Card5';
import Card6 from './ProductTabs/Card6';
import Card7 from './ProductTabs/Card7';

export default function Products() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    }
    return (
        <div>

            <h1 className='heading' id='products'>Our Products & Services</h1>

            <Tabs
                selectedIndex={activeTab}
                onSelect={handleTabClick}
                className="custom-tabs-container"
            >
                <TabList className='tabslist'>
                    <Tab className="custom-tab-item">MEDICAL</Tab>
                    <Tab className="custom-tab-item">DENTAL</Tab>
                    <Tab className="custom-tab-item">LABORATORY</Tab>
                    <Tab className="custom-tab-item">CONSUMABLES</Tab>
                    <Tab className="custom-tab-item">X-RAY IMAGING</Tab>

                    <Tab className="custom-tab-item">DETERGENTS & ANTISEPTICS</Tab>
                    <Tab className="custom-tab-item">BIOMEDICAL ENGINEERING SERVICES</Tab>

                </TabList>

                <TabPanel>
                    <Card1 />
                </TabPanel>
                <TabPanel>
                    <Card2 />
                </TabPanel>
                <TabPanel>
                    <Card3 />
                </TabPanel>
                <TabPanel>
                    <Card4 />
                </TabPanel>
                <TabPanel>
                    <Card5 />
                </TabPanel>
                <TabPanel>
                    <Card7 />
                </TabPanel>
                <TabPanel>
                    <Card6 />
                </TabPanel>



            </Tabs>

        </div>
    )
}
