import React from 'react'
import './App.css';
import MYNavbar from './Components/Navbar';
import MYCarousel from './Components/Carousel';
import Products from './Components/Products';
import About from './Components/About';
import Footer from './Components/Footer';



export default function MainPage() {
    return (
        <div>
            <MYNavbar />
            <MYCarousel />
            <Products />
            <About />
            <Footer />
        </div>
    )
}
